<template>
    <div>
        <div class="box" @click.self="shopShow = false" v-loading.fullscreen.lock="fullscreenLoading">
            <export_excel :json_data="json_data" :json_fields="json_fields" name="短视频.xls" />
            <media_screen path_arr="path_array1" :category_id="7" @screen_confirm="pageChange(1)"
                @reset_screen="pageChange(1)" />
            <Title title="资源列表" />
            <div class="table_box">
                <table>
                    <thead>
                        <td>
                            <input :disabled="tableData.length == 0" type="checkbox" v-model="Allcheck"
                                @click="checkSwitch()" label="" />
                        </td>

                        <td>平台类型</td>
                        <td>媒介名称</td>
                        <td>短视频号</td>
                        <td>价格</td>
                        <td>行业类型</td>
                        <td>所属区域</td>
                        <td>粉丝数</td>
                        <td>受众性别</td>
                        <td>受众年龄</td>
                        <td>可发内链</td>
                        <td>可发联系方式</td>
                        <td>备注(仅供参考)</td>
                        <td>操作</td>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in tableData" :key="index">
                            <td>
                                <input type="checkbox" v-model="item.check" @click="onPitch(index)" label="" />
                            </td>
                            <td>{{ item.portal_title }}</td>
                            <td>
                                <td_remark :text="item.title" />
                            </td>
                            <td>
                                <td_remark :text="item.account" />

                            </td>
                            <td>￥{{ item.price }}</td>
                            <td>{{ item.industry_classify_title }}</td>
                            <td>{{ item.area_title }}</td>
                            <td>{{ item.fans_num }}</td>
                            <td>{{ item.audience_sex == 0 ? '不限' : item.audience_sex == 1 ? '女性粉丝偏多' :
            item.audience_sex == 2 ? '男性粉丝偏多' : '男女粉丝均衡' }}</td>
                            <td>{{ item.audience_age == 0 ? '不限' : item.audience_age == 1 ? '0-17岁偏多' :
            item.audience_age == 2 ? '18-24岁偏多' : item.audience_age == 0 ? '25-34岁偏多' :
                item.audience_age == 0 ? '35-44岁偏多' : '44岁以上偏多' }}</td>
                            <td :style="{ color: item.is_pub_link == 1 ? '#55C964' : '#FF0000' }">
                                {{ item.is_pub_link == 0 ? '否' : '是' }}
                            </td>
                            <td :style="{ color: item.is_pub_contact == 1 ? '#55C964' : '#FF0000' }">
                                {{ item.is_pub_contact == 0 ? '否' : '是' }}
                            </td>
                            <td>
                                <td_remark :text="item.remark" />
                            </td>
                            <td>
                                <div class="collect_box flex">
                                    <p @click.stop="jumpLink(item)">查看案例</p>
                                    <!-- v-if="item.collect == 0"  -->
                                    <p v-if="item.is_collect == 0" class="collect" @click.stop="collectGet(item)">收藏
                                    </p>
                                    <!-- v-if="item.collect == 1" -->
                                    <p v-if="item.is_collect == 1" class="collect2" @click.stop="collectClose(item)">已收藏
                                    </p>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <!-- 分页器 -->
                <pagination :page="page" @pageChange="pageChange" :count="count" :total_page="total_page" />
            </div>
        </div>
        <!-- 购物车 -->
        <div v-show="shopShow == false" class="shopping_car">
            <div class="car" @click.stop="shopShow = true">
                <p>{{ CarList.length }}</p>
            </div>
        </div>
        <div :class="shopShow ? 'shop_box' : 'shop_none_box'">
            <div class="title flex" style="justify-content: space-between; align-items: center">
                <h3 class="carTitle">已选媒体: {{ CarList.length }}</h3>
                <button class="empty_but" v-show="CarList.length != 0" @click="empty()">清空已选媒体</button>
                <span class="close" @click="shopShow = false"></span>
            </div>
            <ul class="listbox" v-show="CarList.length != 0">
                <li v-for="(item, index) in CarList" :key="index">
                    <!-- <img :src="item.logo" alt="" /> -->
                    <div>
                        <td_remark :text="item.title" />
                    </div>
                    <p class="price">￥{{ item.price.toFixed(2) }}</p>
                    <span class="deleteIcon" @click.stop="detale(item)"></span>
                </li>
            </ul>
            <el-empty v-show="CarList.length == 0" description="您还没有选择资源赶紧去看看心仪的资源吧"></el-empty>
            <div class="bottom_box">
                <div class="flex" style="justify-content: space-between; margin-bottom: 1rem">
                    <p style="font-size: 1.4rem; color: #999">合计：</p>
                    <p class="totalMoney">
                        ￥<span>{{ totalMoney }}</span>
                    </p>
                </div>
                <button class="put_but" @click="putFn(item)">立即投放</button>
            </div>
        </div>
    </div>
</template>

<script>
import UserMediaNav from '@/components/UserMediaNav.vue'
import { mapState } from 'vuex'
import { checkAll } from '@/util/checkAll'
import export_excel from '@/components/export_excel.vue';
import media_screen from '@/components/media_screen.vue';
import { media_screen_ } from '@/util/media_screen_'
export default {
    computed: {
        ...mapState(['media_screen_data', 'fillActive']),
        totalMoney: function () {
            var n = 0;
            this.CarList.forEach((item, index) => {
                if (this.CarList[index].check) {
                    n += this.CarList[index].price
                }
            })
            return n.toFixed(2)
        }
    },
    components: {
        export_excel,
        media_screen
    },
    watch: {
        tableData: {
            handler(newValue) {
                if (this.tableData.length > 0) {
                    let count = 0
                    for (var i = 0; i < this.tableData.length; i++) {
                        if (this.tableData[i].check == true) {
                            count++;
                        }
                    }
                    if (count == this.tableData.length) {
                        this.Allcheck = true
                    } else {
                        this.Allcheck = false
                    }
                }
            },
            deep: true,
        },
        CarList: {
            handler() {
                this.excelPush()
            },
            deep: true,
        }
    },
    data() {
        return {
            /*数据表格*/
            tableData: [],
            CarList: [],
            // 购物车任务栏切换
            shopShow: false,
            Allcheck: false,
            AllcheckNum: 0, //选中总数
            ids: [],
            fullscreenLoading: false,
            count: 0, //总条数
            total_page: 0, //分页总页数
            page: 1,
            json_fields: {
                '账户': 'account',
                "账号名称": 'title',
                "价格": 'price',
                "行业类型": 'industry_classify_title',
                "所属区域": 'area_title',
                "案例链接": 'case_url',
                "可带链接": 'is_pub_url',
                "可发联系方式": 'is_pub_url',
                "收录效果": 'inlet_category',
                "受众年龄": 'audience_age',
                "备注": 'remark',
            },
            json_data: []
        }
    },
    methods: {
        pageChange(pageVal) {
            this.page = pageVal
            this.get_list()
        },
        onPitch(index) {
            // 飞入购物车动画
            this.tableData[index].check = !this.tableData[index].check
            if (this.tableData[index].check) {
                const x = event.clientX - 20
                const y = event.clientY - 20
                this.createBall(x, y)
                this.shopShow = true
                // this.CarList.push(this.tableData[index])
                this.CarList.push(this.tableData[index])
                /*------------------------------------------------------ */
            } else {
                this.tableData.forEach((item, index) => {
                    this.CarList.forEach((el, i) => {
                        if (!this.tableData[index].check && this.CarList[i].id == this.tableData[index].id) {
                            this.CarList.splice(i, 1)
                            this.AllcheckNum--

                        }
                    })
                })
            }
        },
        detale(element) {
            this.CarList.forEach((el, i) => {
                if (el.id == element.id) {
                    this.CarList.splice(i, 1)
                }
            })
            // this.CarList.splice(index,1)
            this.tableData.forEach((item, index) => {
                if (item.id == element.id) {
                    this.AllcheckNum--
                    this.tableData[index].check = false
                }
            })
        },
        // 清空购物车
        empty() {
            this.$confirm('是否清空已选媒体?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    this.fullscreenLoading = true
                    // setTimeout(() => {
                    this.CarList = []
                    this.shopShow = false
                    // this.toggleSelection()
                    this.tableData.forEach((item, index) => {
                        if (item.check) {
                            console.log(item)
                            return (item.check = false)
                        }
                    })
                    this.fullscreenLoading = false
                    // }, 500)
                    this.$message({
                        type: 'success',
                        message: '已清空已选媒体',
                    })
                })
                .catch(() => {
                    this.$message({
                        type: 'warning',
                        message: '已删除',
                    })
                })
        },
        createBall(left, top) {
            const bar = document.createElement('ball')
            bar.style.position = 'fixed'
            bar.style.left = left + 'px'
            bar.style.top = top + 'px'
            bar.style.width = '20px'
            bar.style.height = '20px'
            bar.style.borderRadius = '50%'
            bar.style.backgroundColor = 'red'
            bar.style.transition =
                'left 1s linear, top 1s cubic-bezier(0.5, -0.5, 1, 1)'
            document.body.appendChild(bar)
            setTimeout(() => {
                const x = document.body.clientWidth * 1.5
                const y = document.body.clientHeight * 1 - 500
                bar.style.top = y + 'px'
                console.log(y)
                bar.style.left = x + 'px'
            }, 0)
            bar.ontransitionend = function () {
                this.remove()
            }
        },
        // 全选 反选
        checkSwitch() {
            this.Allcheck = !this.Allcheck
            this.CarList = checkAll(this.Allcheck, this.tableData, this.CarList)
            if (this.Allcheck) {
                this.shopShow = true
            }
        },
        // 获取列表数据
        get_list() {
            this.fullscreenLoading = true
            let data = media_screen_(this.$store.state.media_screen_data.date_form)
            data.page = this.page
            data.limit = 20
            this.curlGet('/api/medium/list', data).then(res => {
                if (res.data.code) {
                    this.tableData = res.data.data.list
                    this.total_page = res.data.data.total_page
                    this.count = res.data.data.count
                    this.tableData.forEach((item, index) => {
                        this.$set(this.tableData[index], 'check', false)
                        if (this.CarList.length != 0) {
                            this.CarList.forEach((el, i) => {
                                if (this.tableData[index].id == this.CarList[i].id) {
                                    this.tableData[index].check = true
                                }
                            })
                        }
                    })
                    this.shopDate(this.tableData, this.$store.state.shopObj, this.CarList)
                }
                this.fullscreenLoading = false
            })
        },
        excelPush() {
            this.json_data = []
            this.CarList.forEach((item, index) => {
                this.json_data.push({
                    account: item.account,
                    title: item.title,
                    price: item.price,
                    industry_classify_title: item.industry_classify_title,
                    case_url: item.case_url,
                    area_title: item.area_title,
                    is_pub_url: item.is_pub_url ? '是' : '否',
                    is_pub_contact: item.is_pub_contact ? '是' : '否',
                    inlet_category: item.inlet_category == 0 ? '不限' : item.inlet_category == 1 ? '包网页收录' : item.inlet_category == 2 ? '网页收录' : item.inlet_category == 3 ? '包百度新闻源收录' : '百度新闻源收录',
                    audience_age: item.audience_age == 0 ? '不限' : item.audience_age == 1 ? '0-17岁偏多' : item.audience_age == 2 ? '18-24岁偏多' : item.audience_age == 0 ? '25-34岁偏多' : item.audience_age == 0 ? '35-44岁偏多' : '44岁以上偏多',
                    remark: item.remark
                })
            })
        },
        // 立即投放
        putFn() {
            if (this.CarList.length == 0) {
                return this.$message({
                    type: 'warning',
                    message: '请选择媒介',
                })
            } else if (!this.$user_info) {
                this.$message({
                    type: 'warning',
                    message: '请登录',
                })
            } else {
                this.$store.commit('shopPush', this.CarList)
                if (this.$store.state.fillActive == false) {

                    this.$router.push('/user/SetMenu/ManuscriptPreview')
                } else {
                    this.$store.commit('childTab', '短视频发布')
                    this.$router.push({
                        path: '/user/SetMenu/fill?id=7',
                    })
                }
            }
        },
        // 收藏
        collectGet(item) {
            console.log(item);
            this.curlGet('/api/medium/collect', {
                mid: item.id,
                style: 1
            }).then(res => {
                console.log(res)
                if (res.data.code) {
                    this.$message({
                        message: '收藏成功',
                        type: 'success'
                    })
                    this.get_list()
                }
            })
        },
        // 取消收藏
        collectClose(item) {
            this.$confirm('是否取消收藏?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.curlGet('/api/medium/collect', {
                    mid: item.id,
                    style: 1
                }).then(res => {
                    console.log(res)
                    if (res.data.code) {
                        this.$message({
                            type: 'success',
                            message: '取消收藏成功!'
                        });
                        this.get_list()
                    }
                })

            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消'
                });
            });

        },
    },
}
</script>

<style lang=scss scoped>
@import '@/scss/shopCar';
@import '@/scss/user_media';
</style>